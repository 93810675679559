.app {
  font-family: Arial, sans-serif;
  height: 100vh;  /* Ensure the entire app takes up full viewport height */
}

.main-page {
  display: flex;
  flex-direction: column;
  height: 100%;  /* Fill the available height */
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: #333;
  color: white;
}

.header h1 {
  font-size: 24px;
}

.tabs-container {
  background-color: #f1f1f1;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.content-container {
  display: grid;
  grid-template-columns: 72px auto;
  grid-template-rows: 60% 40%;  /* Top row for document/extracted info, bottom row for workflow/query */
  height: 100%;
  gap: 12px;  /* Add space between elements */
  padding: 10px;
  background-color: #333;
}

.main-content {
  display: flex;
  flex-direction: column;
  background-color: #333;
  color: white;
  box-sizing: border-box;
}

/* Adjusting the individual grid areas */
.document-viewer {
  grid-row: 1 / 2;
  grid-column: 1 / 2;
  background-color: #333;
  padding: 20px;
  overflow-y: auto;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

/* Modular/extracted information boxes at the top right */
.modular-boxes {
  grid-row: 1 / 2;
  grid-column: 2 / 3;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #333;
}

.modular-box {
  background-color: #444;
  padding: 15px;
  border: 1px solid #555;
  flex-grow: 1;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.modular-box h3 {
  color: #007BFF;
}

/* Workflow box on the bottom left */
.workflow-box {
  grid-row: 2 / 3;
  grid-column: 1 / 2;
  background-color: #333;
  padding: 20px;
  overflow-y: auto;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.workflow-box h3 {
  margin-bottom: 10px;
  color: #007BFF;
}

.workflow-item {
  padding: 10px;
  background-color: #444;
  margin-bottom: 10px;
}

.add-document-btn {
  background-color: #007BFF;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  margin-top: 10px;
}

.add-document-btn:hover {
  background-color: #0056b3;
}

/* Query box in the bottom right */
.query-box {
  grid-row: 2 / 3;
  grid-column: 2 / 3;
  display: flex;
  flex-direction: column;
  background-color: #444;
  padding: 20px;
  overflow-y: auto;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.query-output {
  flex-grow: 1;
  margin-bottom: 10px;
  background-color: #333;
  padding: 10px;
  border-radius: 8px;
}

.response-item {
  padding: 10px;
  margin-bottom: 5px;
  background-color: #555;
  border-radius: 4px;
}

.query-form {
  display: flex;
}

.query-input {
  flex-grow: 1;
  padding: 10px;
  border: none;
  border-radius: 4px;
}

.query-submit-btn {
  background-color: #007BFF;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  margin-left: 10px;
}

.query-submit-btn:hover {
  background-color: #0056b3;
}
