.grid-container {
  display: grid;
  grid-template-columns: 54% 46%;  /* changed from 54% 46% */
  grid-template-rows: minmax(60%, 60%) minmax(35%, 35%);
  height: 100vh;
  min-height: 100vh;
  gap: 30px;
  padding: 10px 35px 10px 15px;  /* changed from 25px */
  background-color: #222;
  color: white;
  box-sizing: border-box;
}

/* Document viewer section in the top left */
.document-viewer {
  top: 0;
  left: 0;
  overflow: hidden;
  background-color: #222;
  display: flex;
  flex-direction: column;
  /* Removed `height: 100%;` because min/max-height fixes the dimension */
  min-height: calc(60vh - 55px);
  max-height: calc(60vh - 55px);
}

.document-iframe {
  flex: 1;
  width: 100%;
  height: calc(100% - 60px);
  border: none;
  overflow-y: auto;
  background-color: white;
  border-radius: 3px;
}

/* Modular boxes for extracted information in the top right */
.modular-boxes {
  grid-row: 1 / 2;
  grid-column: 2 / 3;
  display: flex;
  flex-direction: column;
  background-color: #333;
  border-radius: 8px;
  padding: 0;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  padding-right: 10px;
}

.modular-box {
  background-color: #444;
  padding: 15px;
  border: 1px solid #555;
  border-radius: 8px;
  flex-grow: 1;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.modular-box h3 {
  color: #007BFF;
  margin-bottom: 10px;
}

/* Bullet points for modular boxes */
.bullet-points {
  list-style-type: none;
  padding-left: 1.2em;
}

.bullet-points li {
  position: relative;
  margin-bottom: 8px;
  padding-left: 1.2em;
  color: #ddd;
}

.bullet-points li::before {
  content: "•";
  position: absolute;
  left: 0;
  color: #007BFF;
  font-size: 1.2em;
  line-height: 1em;
}

/* Workflow box enhancements */
.workflow-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  background-color: #444;
  margin-bottom: 10px;
  border-radius: 8px;
  position: relative;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.workflow-item:hover {
  background-color: #555;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

/* Style for checkbox in workflow items */
.workflow-item input[type="checkbox"] {
  margin: 0;
  cursor: pointer;
  width: 16px;
  height: 16px;
  accent-color: #007BFF;
}

/* Label styling for each document item */
.workflow-item label {
  flex-grow: 1;
  cursor: pointer;
  margin: 0;
  color: #e0e0e0;
}

/* Close button for removing workflow items */
.remove-btn {
  background: none;
  border: none;
  color: #bbb;
  font-size: 16px;
  cursor: pointer;
  transition: color 0.3s;
  padding: 0 5px;
}

.remove-btn:hover {
  color: #007BFF;
}

/* Add document button */
.add-document-btn {
  background-color: #007BFF;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  margin-top: 10px;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.add-document-btn:hover {
  background-color: #0056b3;
}

/* Query box in the bottom right */
.query-box {
  grid-row: 2 / 3;
  grid-column: 2 / 3;
  display: flex;
  flex-direction: column;
  background-color: #444;
  padding: 20px 20px 80px 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  position: relative;
  overflow: hidden;
}

.query-output {
  flex-grow: 1;
  background-color: #333;
  padding: 10px;
  border-radius: 8px;
  overflow-y: auto;
  max-height: calc(100% - 60px);
  padding-right: 10px;
}

.response-item {
  padding: 10px;
  margin-bottom: 5px;
  background-color: #555;
  border-radius: 8px;
}

.query-form {
  display: flex;
  position: absolute;
  bottom: 2px;
  left: 20px;
  right: 20px;
  background-color: #444;
  padding: 10px;

}

.query-input {
  flex-grow: 1;
  padding: 16px 15px;
  border: 1px solid #555;
  border-radius: 6px;
  background-color: #e8e8e8;
  color: #000000;
  font-size: 14px;
  transition: all 0.3s ease;
}

.query-input:focus {
  outline: none;
  border-color: #007BFF;
  background-color: #ffffff;
}

.query-submit-btn {
  background-color: #007BFF;
  color: white;
  border: none;
  padding: 12px 20px;  
  margin-left: 10px;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  min-width: 100px;
}

.query-submit-btn:hover {
  background-color: #0056b3;
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.query-submit-btn:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* Container for Facts section */
.facts-container {
  grid-row: 1 / 2;
  grid-column: 2 / 3;
  display: flex;
  flex-direction: column;
  background-color: #333;
  border-radius: 8px;
  /* Set min/max-height to fix vertical size */
  min-height: calc(65vh - 55px);
  max-height: calc(65vh - 55px);
  overflow: hidden;
}

.facts-title {
  font-size: 1.5em;
  color: #007BFF;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: left;
}

.document-dropdown {
  position: absolute;
  background-color: #333;
  border: 1px solid #555;
  border-radius: 8px;
  padding: 10px;
  margin-top: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 20%;
  z-index: 10;
}

.document-dropdown-item {
  padding: 8px;
  cursor: pointer;
  color: #e0e0e0;
  font-size: 14px;
  border-bottom: 1px solid #444;
  transition: background-color 0.3s;
}

.document-dropdown-item:last-child {
  border-bottom: none;
}

.document-dropdown-item:hover {
  background-color: #444;
  color: #007BFF;
}

/* Fixed header for tabs */
.tab-navigation {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #222;
  flex-shrink: 0;
  position: relative;
  border-bottom: 1px solid #444;
  margin: 0;
}

.tab-navigation button {
  background-color: #444;
  border: none;
  color: #e0e0e0;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 20px;
  font-weight: 500;
  font-size: 15px;
  transition: background-color 0.3s, color 0.3s;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.tab-navigation button.active {
  background-color: #007BFF;
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

/* Unified modular-content definition */
.modular-content {
  flex: 1;
  overflow: auto;
  padding: 15px;
  background-color: #333;
  height: calc(100% - 50px);
  min-height: calc(60vh - 95px);
  padding-right: 10px;
}

/* Notebook styles */
.notebook {
  display: flex;
  height: 100%;
  background-color: #333;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  min-height: calc(60vh - 95px);
}

.notes-list {
  width: 30%;
  background-color: #333;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #444;
  position: relative;
  height: 100%;
}

.notes-list.collapsed {
  width: 40px;
}

.notes-list-header {
  flex-shrink: 0;
  height: 40px;
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #333;
  border-bottom: 1px solid #444;
  display: flex;
  align-items: center;
  padding: 0;
}

.toggle-sidebar-btn {
  background: none;
  border: none;
  color: #007BFF;
  font-size: 24px;
  cursor: pointer;
  padding: 0 5px;
  transition: color 0.3s;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.toggle-sidebar-btn:hover {
  color: #0056b3;
}

.add-note-btn {
  width: 40px;
  height: 40px;
  padding: 0;
  margin: 0;
  background-color: #444;
  border: none;
  color: #007BFF;
  font-size: 24px;
  cursor: pointer;
  position: relative;
  margin-left: auto;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.add-note-btn:hover {
  background-color: #007BFF;
  color: white;
}

.notes-list-content {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
}

.notes-list.collapsed .add-note-btn {
  display: none;
}

.notes-list.collapsed .notes-list-content {
  display: none;
}

.note-item {
  display: flex;
  align-items: center;
  background-color: #444;
  padding: 8px 12px;
  margin-bottom: 5px;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
  justify-content: space-between;
}

.note-item:hover {
  background-color: #555;
  transform: translateX(2px);
}

.note-item.active {
  background-color: #007BFF;
  color: white;
  transform: translateX(4px);
}

.note-title {
  padding-right: 8px;
}

.delete-note-btn {
  background: none;
  border: none;
  color: #bbb;
  font-size: 18px;
  cursor: pointer;
  padding: 0 5px;
  transition: color 0.3s;
  opacity: 0.7;
  line-height: 1;
}

.delete-note-btn:hover {
  color: #007BFF;
  opacity: 1;
}

.note-item.active .delete-note-btn {
  color: rgba(255, 255, 255, 0.8);
}

.note-item.active .delete-note-btn:hover {
  color: #007BFF;
}

.note-editor {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #333;
  min-height: 100%;
}

.note-title-input {
  font-size: 1.5em;
  margin-bottom: 15px;
  padding: 8px 12px;
  border: none;
  border-radius: 6px;
  background-color: #444;
  color: white;
  transition: background-color 0.2s ease;
}

.note-title-input:focus {
  background-color: #555;
  outline: none;
}

.note-content-input {
  flex-grow: 1;
  resize: none;
  padding: 12px;
  border: none;
  border-radius: 6px;
  background-color: #444;
  color: white;
  line-height: 1.5;
  transition: background-color 0.2s ease;
}

.note-content-input:focus {
  background-color: #555;
  outline: none;
}

/* Scrollbar styling for notes list */
.notes-list::-webkit-scrollbar {
  width: 6px;
}

.notes-list::-webkit-scrollbar-thumb {
  background-color: #555;
  border-radius: 3px;
}

/* Workflow box */
.workflow-box {
  grid-row: 2 / 3;
  grid-column: 1 / 2;
  background-color: #333;
  border-radius: 8px;
  padding: 15px;
  overflow-y: auto;
  max-height: calc(35vh - 25px);
  height: calc(35vh - 55px);
  min-height: 200px;
  margin-top: 5px;
}

/* Stats section */
.stats-section {
  overflow-y: auto;
  max-height: 100%;
  padding-right: 10px;
  padding: 15px;
  height: auto;
  width: 100%;
}

.stats-section h3 {
  color: #007BFF;
  margin: 20px 0 15px 0;
  font-size: 1.2em;
}

.stats-section h3:first-child {
  margin-top: 0;
}

.stats-section table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 25px;
  background-color: #444;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border: 1px solid #555;
}

.stats-section th {
  background-color: #333;
  color: #007BFF;
  font-weight: 500;
  text-align: left;
  padding: 12px 15px;
  border-bottom: 2px solid #555;
  border-right: 1px solid #555;
}

.stats-section td {
  padding: 10px 15px;
  border-bottom: 1px solid #555;
  color: #e0e0e0;
  border-right: 1px solid #555;
  position: relative;
}

.stats-section tr:last-child td {
  border-bottom: none;
}

.stats-section th:last-child, .stats-section td:last-child {
  border-right: none;
}

.stats-section tr:hover td {
  background-color: #4a4a4a;
}

/* New styles for percentage changes */
.percentage-positive {
  background-color: rgba(40, 167, 69, 0.2) !important;
}

.percentage-negative {
  background-color: rgba(220, 53, 69, 0.2) !important;
}

/* Updated percentage change styles */
td.positive {
  color: #00ff00 !important;
  background-color: rgba(40, 167, 69, 0.15);
}

td.negative {
  color: #ff4444 !important;
  background-color: rgba(220, 53, 69, 0.15);
}
