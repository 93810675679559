.company-tabs {
  display: flex;
  align-items: center;
  background-color: #2c2c2c;
  padding: 10px 16px;
  position: relative;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.tab {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Ensures spacing between text and close button */
  padding: 12px 16px;
  cursor: pointer;
  background-color: #444;
  color: #e0e0e0;
  border-radius: 20px;
  margin-right: 12px;
  font-size: 16px;
  font-weight: 500;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, box-shadow 0.3s;
}

.tab:hover {
  background-color: #555;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.tab.active {
  background: linear-gradient(135deg, #007BFF, #0056b3);
  color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  font-weight: 600;
}

/* Close button styling */
.close-btn {
  background: none;
  border: none;
  color: #bbb;
  margin-left: 10px; /* Added margin for spacing from text */
  padding: 2px 6px; /* Padding around the close button for better click area */
  cursor: pointer;
  font-size: 14px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: color 0.3s, background-color 0.3s;
}

.close-btn:hover {
  color: #fff;
  background-color: #666; /* Background on hover for better visibility */
}

.plus-button {
  font-size: 24px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #444;
  border-radius: 50%;
  color: #e0e0e0;
  margin-left: auto;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.plus-button:hover {
  background-color: #555;
  transform: scale(1.1);
}

.company-tabs-dropdown {
  position: absolute;
  top: 50px; /* Position dropdown below the tabs */
  right: 16px;
  background-color: #333;
  border: 1px solid #555;
  border-radius: 8px;
  margin-top: 8px;
  z-index: 10;
  width: 220px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  overflow: hidden;
}

.company-tabs-dropdown-item {
  padding: 12px 16px;
  background-color: #444;
  color: #e0e0e0;
  cursor: pointer;
  font-size: 15px;
  transition: background-color 0.2s, color 0.2s;
}

.company-tabs-dropdown-item:hover {
  background-color: #555;
  color: #007BFF;
}

.company-tabs-dropdown h3 {
  margin: 0;
  padding: 10px 16px;
  background-color: #444;
  color: #ddd;
  font-size: 14px;
  border-bottom: 1px solid #555;
  font-weight: 500;
}
