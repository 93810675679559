.document-uploader {
  position: relative;
  padding: 20px;
  background-color: whitesmoke;
  border-radius: 8px;
  width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: #555;
}

.type-button {
  padding: 12px 20px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.type-button:hover {
  background-color: #0056b3;
  box-shadow: 0 6px 12px rgba(0, 86, 179, 0.3);
}

.document-uploader-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 10px;
  background-color: #333;
  border: 1px solid #555;
  border-radius: 8px;
  width: 100%;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  z-index: 10;
  color: white;
}

.dropdown-section h3 {
  padding: 10px 16px;
  margin: 0;
  background-color: #444;
  font-size: 14px;
  border-bottom: 1px solid #555;
  font-weight: 500;
  color: #ddd;
  cursor: pointer;
  transition: background-color 0.3s;
}

.dropdown-section h3:hover {
  background-color: #555;
}

.dropdown-content {
  padding: 10px;
  background-color: #333;
}

.document-uploader-dropdown-item {
  padding: 10px 16px;
  background-color: #444;
  color: #e0e0e0;
  cursor: pointer;
  font-size: 15px;
  transition: background-color 0.2s;
}

.document-uploader-dropdown-item:hover {
  background-color: #555;
  color: #007BFF;
}
