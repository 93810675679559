.login-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0;
  }
  
  .login-page h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }
  
  .login-page p {
    margin-bottom: 15px;
    font-size: 16px;
    color: #666;
  }
  
  .login-page form {
    display: flex;
    flex-direction: column;
  }
  
  .login-page input {
    padding: 10px;
    margin-bottom: 10px;
    width: 250px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .login-page button {
    padding: 10px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .login-page button:hover {
    background-color: #0056b3;
  }
  